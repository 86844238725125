<template>
  <div class="content">
    <h1 class="title title--theme title--indent">Добавление номера</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid class="grid--indent">
        <template #item-1>
          <app-form-group required label="Направление">
            <v-select
              :options="directions_options"
              @input="onChangeDirection"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.direction.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.direction.$dirty && !$v.form.direction.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <template>
            <app-form-group v-if="form.direction && (form.direction !== 'region' ||  app_name === 'oovo')" required
                            label="Номинация">
              <v-select
                v-model="form.nomination"
                :options="nominations_options"
                @input="onChangeNomination"
                :filterable="true"
                :clearable="false"
                label="name"
                placeholder="Выберите значение"
                class="select"
                :class="{ 'select--error': $v.form.nomination.$error }"
              >
                <template #open-indicator>
                  <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                    <path
                      d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                      fill="#fff"></path>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
                <template #no-options>Ничего не найдено</template>
              </v-select>
              <template #error>
                <div v-if="$v.form.nomination.$dirty && !$v.form.nomination.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group v-if="form.nomination && form.nomination.has_subnominations" required label="Подноминация">
              <v-select
                v-model="form.subnomination"
                :reduce="item => item.id"
                :options="subnominations_options"
                @input="onChangeSubNomination"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                label="name"
                placeholder="Выберите значение"
                class="select"
                :class="{ 'select--error': $v.form.subnomination.$error }"
              >
                <template #open-indicator>
                  <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                    <path
                      d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                      fill="#fff"></path>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
                <template #no-options>Ничего не найдено</template>
              </v-select>
              <template #error>
                <div v-if="$v.form.subnomination.$dirty && !$v.form.subnomination.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group v-if="['common', 'fashion', 'art', 'journal'].includes(form.direction)" required
                            label="Категория">
              <app-cells position="start" :indent="false">
                <app-radio
                  v-for="category in category_options"
                  :key="category.id"
                  v-model.number="form.category"
                  :value="category.id"
                  :label="category.name"
                  name="category"
                />
              </app-cells>
              <template #error>
                <div v-if="$v.form.category.$dirty && !$v.form.category.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group v-if="['common', 'fashion', 'art', 'journal'].includes(form.direction)" required
                            label="Форма участия">
              <v-select
                v-model="form.amount"
                :options="amount_options"
                :filterable="false"
                :clearable="false"
                :searchable="false"
                @input="onChangeAmount"
                label="name"
                placeholder="Выберите значение"
                class="select"
                :readonly="amountReadonly"
                :class="{ 'select--error': $v.form.amount.$error }"
              >
                <template #open-indicator>
                  <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                    <path
                      d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                      fill="#fff"></path>
                  </svg>
                </template>
                <template #option="option">
                  <div class="select__item d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
                <template #no-options>Ничего не найдено</template>
              </v-select>
              <template #error>
                <div v-if="$v.form.amount.$dirty && !$v.form.amount.required">Обязательное поле</div>
              </template>
            </app-form-group>
          </template>
          <app-form-group v-if="form.direction" required :label="numberNameLabel" label-for="number_name">
            <app-input
              v-model="numberName"
              id="number_name"
              placeholder="Введите значение"
              :error="$v.form.number_name.$error"
              key="number-name"
            />
            <template #error>
              <div v-if="$v.form.number_name.$dirty && !$v.form.number_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            v-if="['region', 'fashion', 'common'].includes(form.direction)"
            required
            :label="form.direction === 'fashion' ? 'Продолжительность показа, мин., сек.' : 'Продолжительность номера, мин., сек.'"
            label-for="duration">
            <app-input
              v-model="form.duration"
              v-mask="'99:99'"
              id="duration"
              autocomplete="off"
              placeholder="Введите значение"
              :error="$v.form.duration.$error"
              @paste.native.prevent
            />
            <template #error>
              <div v-if="$v.form.duration.$dirty && !$v.form.duration.required">Обязательное поле</div>
              <div v-if="$v.form.duration.$dirty && !$v.form.duration.underscorePresent">Заполните поле полностью</div>
              <div v-if="$v.form.duration.$dirty && durationExceed">Указанная продолжительность номера превышает допустимую по регламенту</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.direction === 'fashion'" label="Количество образов в коллекции"
                          label-for="cnt_works">
            <app-input
              v-model="form.cnt_works"
              id="cnt_works"
              type="number"
              placeholder="Введите значение"
            />
          </app-form-group>
          <app-form-group
            v-if="form.amount && form.amount.collective_name_required"
            required
            label="Название коллектива"
            label-for="collective_name"
          >
            <app-input
              v-model="form.collective_name"
              id="collective_name"
              placeholder="Введите значение"
              :error="$v.form.collective_name.$error"
            />
            <template #error>
              <div v-if="$v.form.collective_name.$dirty && !$v.form.collective_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group v-if="form.direction" :label="participantsLabel" required>
            <v-select
              v-model="form.participants"
              :reduce="item => item.pk"
              :options="participantOptions"
              :filterable="true"
              :selectable="item => item.has_certificate_scan"
              label="last_name"
              placeholder="Выберите участников"
              class="select select--multiple"
              :class="{ 'select--error': $v.form.participants.$error }"
              multiple
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.last_name }} {{ option.first_name }}
                  {{ option.patronymic ? option.patronymic : '' }}
                </div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.last_name }} {{ option.first_name }}
                  {{ option.patronymic ? option.patronymic : '' }}
                </div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.participants.$dirty && !$v.form.participants.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            v-if="['region', 'fashion', 'common'].includes(form.direction)"
            :label="stuffLabel"
          >
            <v-select
              v-model="form.stuff"
              :reduce="item => item.pk"
              :options="stuffOptions"
              :filterable="true"
              label="last_name"
              placeholder="Выберите участников"
              class="select select--multiple"
              multiple
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.last_name }} {{ option.first_name }}
                  {{ option.patronymic ? option.patronymic : '' }}
                </div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.last_name }} {{ option.first_name }}
                  {{ option.patronymic ? option.patronymic : '' }}
                </div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
          </app-form-group>
          <template v-if="form.direction === 'fashion'">
            <app-form-group
              label="Необходимое количество приглашенных организаторами моделей"
              label-for="cnt_people_needed"
            >
              <app-input
                key="cnt_people_needed"
                v-model="form.cnt_people_needed"
                id="cnt_people_needed"
                type="number"
                placeholder="Введите значение"
              />
            </app-form-group>
            <app-form-group label="Ссылка на видео показа конкурсной работы" label-for="fashion_video">
              <app-input
                key="fashion_video"
                v-model="form.links.fashion_video"
                id="fashion_video"
                placeholder="Введите значение"
              />
            </app-form-group>
            <app-form-group label="Ссылка на резюме автора" label-for="fashion_resume">
              <app-input
                key="fashion_resume"
                v-model="form.links.fashion_resume"
                id="fashion_resume"
                placeholder="Введите значение"
              />
            </app-form-group>
            <app-form-group label="Ссылка на лук-бук коллекции" label-for="fashion_lookbook" required>
              <app-input
                key="fashion_lookbook"
                v-model="form.links.fashion_lookbook"
                id="fashion_lookbook"
                placeholder="Введите значение"
                :error="$v.form.links.fashion_lookbook.$error"
              />
              <template #error>
                <div v-if="$v.form.links.fashion_lookbook.$dirty && !$v.form.links.fashion_lookbook.required">Обязательное
                  поле
                </div>
              </template>
            </app-form-group>
            <app-form-group label="Ссылка на дополнительные материалы" label-for="fashion_adds">
              <app-input
                key="fashion_adds"
                v-model="form.links.fashion_adds"
                id="fashion_adds"
                placeholder="Введите значение"
              />
            </app-form-group>
          </template>
          <app-form-group
            v-if="form.direction === 'journal'"
            label="Ссылка на облачное хранилище, где размещена работа с регионального этапа"
            label-for="journal_cloud"
            required
          >
            <app-input
              key="journal_cloud"
              v-model="form.links.journal_cloud"
              id="journal_cloud"
              placeholder="Введите значение"
              :error="$v.form.links.journal_cloud.$error"
            />
            <template #error>
              <div v-if="$v.form.links.journal_cloud.$dirty && !$v.form.links.journal_cloud.required">Обязательное
                поле
              </div>
            </template>
          </app-form-group>
          <template v-if="form.direction === 'art'">
            <app-form-group
              label="Ссылка на облачное хранилище, где размещено портфолио участника"
              label-for="art_cloud"
              required
            >
              <app-input
                key="art_cloud"
                v-model="form.links.art_cloud"
                id="art_cloud"
                placeholder="Введите значение"
                :error="$v.form.links.art_cloud.$error"
              />
              <template #error>
                <div v-if="$v.form.links.art_cloud.$dirty && !$v.form.links.art_cloud.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group
              :label="app_name === 'oovo' ? 'Ссылка на эскиз конкурсной работы. Необходимо предоставить до 12го мая' : 'Ссылка на эскиз конкурсной работы (объект: Квадратная конструкция 2,5х2,5 метров)'"
              label-for="art_sketch"
            >
              <app-input
                key="art_sketch"
                v-model="form.links.art_sketch"
                id="art_sketch"
                placeholder="Введите значение"
              />
            </app-form-group>
          </template>
          <app-form-group v-if="form.direction === 'region'" label="Участники эпизодов">
            <div class="form-table">
              <div class="form-table__head">
                <div class="form-table__item">ФИО</div>
                <div class="form-table__item">Возраст</div>
                <div class="form-table__item">Занятость</div>
              </div>
              <div
                v-for="(item, index) in form.episode_roles"
                :key="index"
                class="form-table__row"
              >
                <button v-if="index > 0" @click="onDeleteItem('episode_roles', index)" class="link link--absolute"
                        type="button">
                </button>
                <div class="form-table__item">
                  <input
                    :id="`name${index}`"
                    type="text"
                    :name="`name${index}`"
                    v-model.trim="item.name"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`age${index}`"
                    type="text"
                    :name="`age${index}`"
                    v-model.trim="item.age"
                  />
                </div>
                <div class="form-table__item">
                  <input
                    :id="`work${index}`"
                    type="text"
                    :name="`work${index}`"
                    v-model.trim="item.work"
                  />
                </div>
              </div>
            </div>
            <app-button size="link" class="btn--link-icon"
                        @click="onCloneItem('episode_roles', {name: '', age: '', work: ''})" type="button">Добавить
              строку
            </app-button>
          </app-form-group>
          <app-form-group
            v-if="form.direction === 'region' || form.direction === 'common'"
            :label="form.direction === 'region' ? 'Автор сценария, звукорежиссер, светорежиссер, сценография и т.д.' : 'Постановщик / режиссер / аранжировщик и т.д.'"
            label-for="directors_info"
            :required="form.direction === 'region'"
          >
            <app-textarea
              v-model="form.directors_info"
              id="directors_info"
              placeholder="Введите значение"
              :error="$v.form.directors_info.$error"
            />
            <template #error>
              <div
                v-if="$v.form.directors_info.$dirty && (form.direction === 'region' && !$v.form.directors_info.required)">
                Обязательное поле
              </div>
            </template>
          </app-form-group>
        </template>
        <template v-if="form.direction" #item-2>
          <template v-if="form.direction === 'region'">
            <app-form-group
              label="ФИО технического руководителя"
              label-for="tech_fio"
              required
            >
              <app-input
                v-model="form.contacts.tech_fio"
                id="tech_fio"
                placeholder="Введите значение"
                :error="$v.form.contacts.tech_fio.$error"
              />
              <template #error>
                <div v-if="$v.form.contacts.tech_fio.$dirty && !$v.form.contacts.tech_fio.required">Обязательное поле
                </div>
              </template>
            </app-form-group>
            <app-form-group label="Телефон технического руководителя" required>
              <app-phone
                v-model="form.contacts.tech_phone"
                :error="$v.form.contacts.tech_phone.$dirty && (!$v.form.contacts.tech_phone.required || (form.contacts.tech_phone === 0))"
                @change.native="$v.form.contacts.tech_phone.$touch()"
                @paste.native.prevent
              />
              <template #error>
                <div v-if="$v.form.contacts.tech_phone.$dirty && !$v.form.contacts.tech_phone.required">Обязательное
                  поле
                </div>
                <div
                  v-if="$v.form.contacts.tech_phone.$dirty && (form.contacts.tech_phone === 0) && $v.form.contacts.tech_phone.required">
                  Неправильный формат номера
                </div>
              </template>
            </app-form-group>
            <app-form-group
              label="Email технического руководителя"
              label-for="tech_email"
              required
            >
              <app-input
                v-model="form.contacts.tech_email"
                id="tech_fio"
                placeholder="Введите значение"
                :error="$v.form.contacts.tech_email.$error"
              />
              <template #error>
                <div v-if="$v.form.contacts.tech_email.$dirty && !$v.form.contacts.tech_email.email">Некорректный формат
                  электронной почты
                </div>
                <div v-if="$v.form.contacts.tech_email.$dirty && !$v.form.contacts.tech_email.required">Обязательное
                  поле
                </div>
              </template>
            </app-form-group>
            <app-form-group
              label="ФИО режиссера"
              label-for="producer_fio"
              required
            >
              <app-input
                v-model="form.contacts.producer_fio"
                id="producer_fio"
                placeholder="Введите значение"
                :error="$v.form.contacts.producer_fio.$error"
              />
              <template #error>
                <div v-if="$v.form.contacts.producer_fio.$dirty && !$v.form.contacts.producer_fio.required">Обязательное
                  поле
                </div>
              </template>
            </app-form-group>
            <app-form-group label="Телефон режиссера" required>
              <app-phone
                v-model="form.contacts.producer_phone"
                :error="$v.form.contacts.producer_phone.$dirty && (!$v.form.contacts.producer_phone.required || (form.contacts.producer_phone === 0))"
                @change.native="$v.form.contacts.producer_phone.$touch()"
                @paste.native.prevent
              />
              <template #error>
                <div v-if="$v.form.contacts.producer_phone.$dirty && !$v.form.contacts.producer_phone.required">
                  Обязательное поле
                </div>
                <div
                  v-if="$v.form.contacts.producer_phone.$dirty && (form.contacts.producer_phone === 0) && $v.form.contacts.producer_phone.required">
                  Неправильный формат номера
                </div>
              </template>
            </app-form-group>
            <app-form-group
              label="E-mail режиссера"
              label-for="producer_email"
              required
            >
              <app-input
                v-model="form.contacts.producer_email"
                id="tech_fio"
                placeholder="Введите значение"
                :error="$v.form.contacts.producer_email.$error"
              />
              <template #error>
                <div v-if="$v.form.contacts.producer_email.$dirty && !$v.form.contacts.producer_email.email">
                  Некорректный формат электронной почты
                </div>
                <div v-if="$v.form.contacts.producer_email.$dirty && !$v.form.contacts.producer_email.required">
                  Обязательное поле
                </div>
              </template>
            </app-form-group>
          </template>
          <template v-else>
            <app-form-group
              label="Контактное лицо"
              label-for="contact_fio"
              required
            >
              <app-input
                v-model="form.contacts.fio"
                id="contact_fio"
                placeholder="Введите значение"
                :error="$v.form.contacts.fio.$error"
              />
              <template #error>
                <div v-if="$v.form.contacts.fio.$dirty && !$v.form.contacts.fio.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group label="Телефон контактного лица" required>
              <app-phone
                v-model="form.contacts.phone"
                :error="$v.form.contacts.phone.$dirty && (!$v.form.contacts.phone.required || (form.contacts.phone === 0))"
                @change.native="$v.form.contacts.phone.$touch()"
                @paste.native.prevent
              />
              <template #error>
                <div v-if="$v.form.contacts.phone.$dirty && !$v.form.contacts.phone.required">Обязательное поле</div>
                <div
                  v-if="$v.form.contacts.phone.$dirty && (form.contacts.phone === 0) && $v.form.contacts.phone.required">
                  Неправильный формат номера
                </div>
              </template>
            </app-form-group>
            <app-form-group
              label="Email контактного лица"
              label-for="contact_email"
              required
            >
              <app-input
                v-model="form.contacts.email"
                id="contact_email"
                placeholder="Введите значение"
                :error="$v.form.contacts.email.$error"
              />
              <template #error>
                <div v-if="$v.form.contacts.email.$dirty && !$v.form.contacts.email.email">Некорректный формат
                  электронной почты
                </div>
                <div v-if="$v.form.contacts.email.$dirty && !$v.form.contacts.email.required">Обязательное поле</div>
              </template>
            </app-form-group>
            <app-form-group
              label="ВКонтакте контактного лица"
              label-for="contact_vk"
            >
              <app-input
                v-model="form.contacts.vk"
                id="contact_vk"
                placeholder="Введите значение"
              />
            </app-form-group>
          </template>
          <app-form-group
            v-if="form.direction && form.direction !== 'region'"
            label="ФИО руководителя коллектива / педагога. Пример «Руководитель/педагог Иванова А.А.»"
            label-for="pedagogue_name"
          >
            <app-input
              v-model="form.pedagogue_name"
              id="pedagogue_name"
              placeholder="Введите значение"
            />
          </app-form-group>
          <app-form-group
            v-if="form.direction && form.direction === 'common'"
            :required="form.nomination && form.nomination.code === 'author'"
            label="Авторский текст (стихи) конкурсного произведения или перевод текста конкурсного произведения, исполняемого на иностранном или народном языке"
          >
            <app-uploader
              v-model="form.text"
              :extensions="['image/*', 'application/pdf', '.docx']"
              :error="$v.form.text.$error"
            />
            <template #error>
              <div v-if="$v.form.text.$dirty && $v.form.text.$error && !$v.form.text.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group
            v-if="['region', 'common', 'fashion'].includes(form.direction)"
            label="Список (описание) музыкальных инструментов, аудио-, видео-, электрооборудования, привезенного с собой, которое необходимо подключить"
            label-for="music_instruments"
          >
            <app-textarea
              v-model="form.music_instruments"
              id="music_instruments"
              placeholder="Введите значение"
            />
          </app-form-group>
          <app-form-group
            label="Список крупногабаритного реквизита (который планируется использовать) с описанием, указанием габаритов и веса"
            label-for="large_props"
            :required="['common', 'region', 'fashion'].includes(form.direction)"
          >
            <app-textarea
              v-model="form.large_props"
              id="large_props"
              placeholder="Введите значение"
              :error="$v.form.large_props.$error"
            />
            <template #error>
              <div v-if="$v.form.large_props.$dirty && $v.form.large_props.$error && !$v.form.large_props.required">
                Обязательное поле
              </div>
            </template>
          </app-form-group>
          <app-form-group
            label="Другая информация, важная по вашему мнению"
            label-for="other_info"
          >
            <app-textarea
              v-model="form.other_info"
              id="other_info"
              placeholder="Введите значение"
            />
          </app-form-group>
        </template>
      </app-grid>
      <app-form-group
        v-if="['region', 'common', 'fashion'].includes(form.direction)"
        label="Список использованных произведений российских и зарубежных правообладателей, в соответствии с реестром РАО"
      >
        <template #additional>
          <app-tooltip>
            <template #icon>
              <icon-tooltip/>
            </template>
            <template #content>
              <a href="http://rao.ru/information/reestry" target="_blank" class="tooltip__link">Реестр РАО</a>
            </template>
          </app-tooltip>
        </template>
        <div class="form-table">
          <div class="form-table__head">
            <div class="form-table__item">Название произведения</div>
            <div class="form-table__item">Жанр</div>
            <div class="form-table__item">Исполнитель</div>
            <div class="form-table__item">Автор музыки</div>
            <div class="form-table__item">Автор текста</div>
          </div>
          <div
            v-for="(item, index) in $v.form.rao.$each.$iter"
            :key="index"
            class="form-table__row"
          >
            <button v-if="index > 0" @click="onDeleteItem('rao', index)" class="link link--absolute"
                    type="button">
            </button>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.name.$error}">
              <input
                :id="`name${index}`"
                type="text"
                :name="`name${index}`"
                v-model.trim="item.name.$model"
              />
            </div>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.genre.$error}">
              <input
                :id="`genre${index}`"
                type="text"
                :name="`genre${index}`"
                v-model.trim="item.genre.$model"
              />
            </div>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.executor.$error}">
              <input
                :id="`executor${index}`"
                type="text"
                :name="`executor${index}`"
                v-model.trim="item.executor.$model"
              />
            </div>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.author_music.$error}">
              <input
                :id="`author_music${index}`"
                type="text"
                :name="`author_music${index}`"
                v-model.trim="item.author_music.$model"
              />
            </div>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.author_text.$error}">
              <input
                :id="`author_text${index}`"
                type="text"
                :name="`author_text${index}`"
                v-model.trim="item.author_text.$model"
              />
            </div>
          </div>
        </div>
        <app-button size="link" class="btn--link-icon"
                    @click="onCloneItem('rao', {name: '', genre: '', executor: '', author_music: '', author_text: ''})"
                    type="button">Добавить строку
        </app-button>
      </app-form-group>
      <app-form-group
        v-if="['region', 'common', 'fashion'].includes(form.direction)"
        label="Список использованных фонограмм, в соответствии с реестром ВОИС"
      >
        <template #additional>
          <app-tooltip>
            <template #icon>
              <icon-tooltip/>
            </template>
            <template #content>
              <a href="http://rosvois.ru/reestr/" target="_blank" class="tooltip__link">Реестр ВОИС</a>
            </template>
          </app-tooltip>
        </template>
        <div class="form-table">
          <div class="form-table__head">
            <div class="form-table__item">Название фонограммы</div>
            <div class="form-table__item">Исполнитель</div>
            <div class="form-table__item">Автор музыки</div>
            <div class="form-table__item">Автор текста</div>
            <div class="form-table__item">Изготовитель фонограммы</div>
          </div>
          <div
            v-for="(item, index) in $v.form.vois.$each.$iter"
            :key="index"
            class="form-table__row"
          >
            <button v-if="index > 0" @click="onDeleteItem('vois', index)" class="link link--absolute"
                    type="button">
            </button>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.name.$error}">
              <input
                :id="`name${index}`"
                type="text"
                :name="`name${index}`"
                v-model.trim="item.name.$model"
              />
            </div>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.executor.$error}">
              <input
                :id="`executor${index}`"
                type="text"
                :name="`executor${index}`"
                v-model.trim="item.executor.$model"
              />
            </div>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.author_music.$error}">
              <input
                :id="`author_music${index}`"
                type="text"
                :name="`author_music${index}`"
                v-model.trim="item.author_music.$model"
              />
            </div>
            <div class="form-table__item"
                 :class="{'form-table__item--error': item.author_text.$error}">
              <input
                :id="`author_text${index}`"
                type="text"
                :name="`author_text${index}`"
                v-model.trim="item.author_text.$model"
              />
            </div>
            <div class="form-table__item" :class="{'form-table__item--error': item.producer.$error}">
              <input
                :id="`producer${index}`"
                type="text"
                :name="`producer${index}`"
                v-model.trim="item.producer.$model"
              />
            </div>
          </div>
        </div>
        <app-button size="link" class="btn--link-icon"
                    @click="onCloneItem('vois', {name: '', executor: '', author_music: '', author_text: '', producer: ''})"
                    type="button">Добавить строку
        </app-button>
      </app-form-group>
      <app-cells>
        <app-button ref="submit" :disabled="$v.form.$error">Добавить номер</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import {getAmount, getDirections, getFMS, getMembersList, getNominations, getSubNominations, postNumber} from '@/http'
import {email, required} from 'vuelidate/lib/validators'
import {debounce} from 'lodash'
import {minutesToSeconds} from 'date-fns'
import IconTooltip from '@/components/icons/IconTooltip'

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'NumbersCreate',
  components: {
    IconTooltip,
  },
  data() {
    return {
      form: {
        collective_name: '',
        direction: '',
        subnomination: '',
        number_name: '',
        amount: '',
        episode_roles: [
          {
            name: '',
            age: '',
            work: '',
          },
        ],
        contacts: {},
        rao: [
          {
            name: '',
            genre: '',
            executor: '',
            author_music: '',
            author_text: '',
          },
        ],
        vois: [
          {
            name: '',
            executor: '',
            author_music: '',
            author_text: '',
            producer: '',
          },
        ],
        links: {},
        nomination: '',
        participants: [],
        stuff: [],
        text: null
      },
      participant_options: [],
      directions_options: [],
      nominations_options: [],
      subnominations_options: [],
      amount_options: [],
      category_options: [
        {
          id: 1,
          name: 'Профильная',
        },
        {
          id: 2,
          name: 'Непрофильная',
        },
      ],
      directionId: null,
      app_name: process.env.VUE_APP_FESTIVAL_NAME,
    }
  },
  validations() {
    const form = {
      direction: {required},
      number_name: {required},
      participants: {required},
      contacts: {
        tech_fio: {},
        tech_email: {},
        tech_phone: {},
        producer_fio: {},
        producer_email: {},
        producer_phone: {},
        fio: {},
        phone: {},
        email: {},
      },
      links: {
        journal_cloud: {},
        art_cloud: {},
        fashion_lookbook: {},
      },
      duration: {},
      collective_name: {},
      directors_info: {},
      category: { required },
      amount: {required},
      nomination: {},
      subnomination: {},
      large_props: {},
      text: {}
      // episode_roles: {
      //   $each: {
      //     name: {},
      //     age: {},
      //     work: {},
      //   },
      // },
    }
    if (this.form.nomination && this.form.nomination.code === 'author') {
      form.text = {required}
    }

    if (['common', 'region', 'fashion'].includes(this.form.direction)) {
      const exceeded = () => !this.durationExceed
      form.duration = {required, underscorePresent, exceeded}

      if (this.form.direction !== 'region') form.large_props = {required}

      form.rao = {
        required,
        minLength: 1,
        $each: {
          name: {
            required,
          },
          genre: {
            required,
          },
          executor: {
            required,
          },
          author_music: {
            required,
          },
          author_text: {
            required,
          },
        }
      }
      form.vois = {
        required,
        minLength: 1,
        $each: {
          name: {
            required,
          },
          producer: {
            required,
          },
          executor: {
            required,
          },
          author_music: {
            required,
          },
          author_text: {
            required,
          },
        }
      }
    }

    if (this.form.amount && this.form.amount.collective_name_required) {
      form.collective_name = {required}
    }

    if (!!this.nominations_options.length) {
      form.nomination = {required}
    }

    if (this.form.direction === 'region') {
      // form.episode_roles = {
      //   $each: {
      //     name: { required },
      //     age: { required },
      //     work: { required },
      //   },
      // }
      form.contacts.tech_fio = {required}
      form.contacts.tech_email = {required, email}
      form.contacts.tech_phone = {required}
      form.contacts.producer_fio = {required}
      form.contacts.producer_email = {required, email}
      form.contacts.producer_phone = {required}
      form.directors_info = {required}
      form.category = {}
      form.amount = {}
    } else {
      form.nomination = {required}
      if (this.form.nomination.has_subnominations) form.subnomination = {required}
      form.contacts.fio = {required}
      form.contacts.phone = {required}
      form.contacts.email = {required, email}
    }

    if (this.form.direction === 'journal') form.links.journal_cloud = {required}
    if (this.form.direction === 'art') form.links.art_cloud = {required}

    if (this.form.direction === 'fashion') {
      form.links.fashion_lookbook = {required}
    }

    return {
      form,
    }
  },
  computed: {
    numberName: {
      get() {
        return this.changeQuotes(this.form.number_name || '')
      },
      set(newValue) {
        this.form.number_name = this.changeQuotes(newValue)
      }
    },
    stuffOptions() {
      if (!this.form.participants.length) return this.participant_options
      return this.participant_options.filter(participant => this.form.participants.every(item => participant.pk !== item))
    },
    participantOptions() {
      if (!this.form.stuff.length) return this.participant_options
      return this.participant_options.filter(participant => this.form.stuff.every(item => participant.pk !== item))
    },
    durationExceed() {
      if (this.form.direction === 'region' && this.form.duration) {
        return this.onConvertToSec(this.form.duration) > 2400
      }
      if (this.form.direction !== 'common' || !this.form.amount || !this.form.duration) return false
      return this.form.amount.max_duration < this.onConvertToSec(this.form.duration)
    },
    amountReadonly() {
      return !this.form.nomination || (this.form.nomination.has_subnominations && !this.form.subnomination)
    },
    numberNameLabel() {
      if (this.form.direction === 'region') return 'Название региональной программы'
      if (this.form.direction === 'common') return 'Название исполняемого произведения / номера'
      if (this.form.direction === 'fashion') return 'Название конкурсной работы / коллекции'
      if (this.form.direction === 'journal') return 'Название работы, за которую получена награда регионального этапа'
      if (this.form.direction === 'art') return 'Название предварительного эскиза'
      return 'Название номера'
    },
    participantsLabel() {
      let labelString = ''
      if (this.form.direction === 'region' || this.form.direction === 'common') labelString = 'Участники номера'
      if (this.form.direction === 'fashion') labelString = 'Авторы коллекции'
      if (this.form.direction === 'journal') labelString = 'Авторы работы'
      if (this.form.direction === 'art') labelString = 'Участники'
      labelString = this.form.participants.length ? `${labelString} (${this.form.participants.length})` : labelString
      return labelString
    },
    stuffLabel() {
      let labelString = ''
      if (this.form.direction === 'fashion') labelString = 'Техническая группа обеспечения показа и модели'
      else labelString = 'Техническая группа'
      labelString = this.form.stuff.length ? `${labelString} (${this.form.stuff.length})` : labelString
      return labelString
    },
    readonlyCondition() {
      return !this.form.direction
    },
    stuffCount() {
      return this.form.stuff.length
    },
  },
  created() {
    getDirections()
      .then(response => {
        this.directions_options = response.data
      })
    getMembersList({page_size: 200})
      .then(response => {
        this.participant_options = response.data.results
      })
  },
  methods: {
    changeQuotes(text) {
      return text.replace(/\x27/g, '\x22').replace(/(\w)\x22(\w)/g, '$1\x27$2').replace(/(^)\x22(\s)/g, '$1»$2').replace(/(^|\s|\()"/g, '$1«').replace(/"(\;|\!|\?|\:|\.|\,|$|\)|\s)/g, '»$1')
    },
    onChangeDirection(val) {
      this.form.direction = val.code
      this.form.nomination = ''
      this.directionId = val.id
      if (val.code === 'region' && this.app_name !== 'oovo') return
      getNominations(this.directionId)
        .then(response => {
          this.nominations_options = response.data
        })
    },
    onChangeNomination() {
      this.form.subnomination = ''
      this.form.amount = ''
      const nominationId = this.nominations_options.find(item => item.id === this.form.nomination.id).id
      getSubNominations(nominationId)
        .then(response => {
          this.subnominations_options = response.data
        })
      getAmount(this.directionId, this.form.nomination.id)
        .then(response => {
          this.amount_options = response.data
        })
    },
    onChangeSubNomination() {
      this.form.amount = ''
      getAmount(this.directionId, this.form.nomination.id, this.form.subnomination)
        .then(response => {
          this.amount_options = response.data
        })
    },
    onChangeAmount() {
      this.form.collective_name = ''
    },
    onConvertToSec(time) {
      const durationArr = time.split(':')
      const minToSec = minutesToSeconds(durationArr[0])
      return minToSec + parseInt(durationArr[1])
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      postNumber(this.normalizeForm())
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Номер добавлен'
          })
          this.$router.push({name: 'numbers-list'})
        })
    },
    onCloneItem(form_field, obj) {
      this.form[form_field].push(obj)
    },
    onDeleteItem(form_field, index) {
      this.form[form_field].splice(index, 1)
    },
    normalizeForm() {
      const form = {...this.form}
      if (form.duration) form.duration = this.onConvertToSec(form.duration)

      if (this.form.direction !== 'region') {
        delete form.episode_roles
        delete form.contacts.tech_fio
        delete form.contacts.tech_email
        delete form.contacts.tech_phone
        delete form.contacts.producer_fio
        delete form.contacts.producer_email
        delete form.contacts.producer_phone
      } else {
        if (this.app_name !== 'oovo') {
          delete form.nomination
        }
        delete form.pedagogue_name
        delete form.subnomination
        delete form.category
        delete form.amount
        delete form.contacts.fio
        delete form.contacts.phone
        delete form.contacts.email
        delete form.contacts.vk
      }
      if (this.form.direction !== 'region' && this.form.direction !== 'common') delete form.directors_info
      if (!['region', 'common', 'fashion'].includes(form.direction)) {
        delete form.music_instruments
        delete form.duration
        delete form.rao
        delete form.vois
        delete form.stuff
      }
      if (this.form.direction === 'common') {
        if (form.text) form.text = form.text.id
        if (!form.amount || !form.amount.collective_name_required) delete form.collective_name
      } else {
        delete form.text
      }
      if (this.form.direction !== 'fashion') {
        delete form.cnt_people_needed
        delete form.cnt_works
        delete form.links.fashion_video
        delete form.links.fashion_resume
        delete form.links.fashion_lookbook
        delete form.links.fashion_adds
      } else {
        if (!form.amount || !form.amount.collective_name_required) delete form.collective_name
      }
      if (this.form.direction !== 'journal') {
        delete form.links.journal_cloud
      } else {
        if (!form.amount || !form.amount.collective_name_required) delete form.collective_name
      }
      if (this.form.direction !== 'art') {
        delete form.links.art_cloud
        delete form.links.art_sketch
      }

      if (!['fashion', 'journal', 'art'].includes(this.form.direction)) delete form.links
      if (!['fashion', 'journal', 'common'].includes(this.form.direction)) delete form.collective_name

      if (form.nomination !== undefined) {
        if (typeof form.nomination === 'object') form.nomination = form.nomination.id
      } else {
        delete form.nomination
      }
      if (form.amount) {
        if (typeof form.amount === 'object') form.amount = form.amount.id
      }
      if (form.subnomination) {
        if (typeof form.subnomination === 'object') form.subnomination = form.subnomination.id
      } else {
        delete form.subnomination
      }
      form.direction = this.directionId
      form.status = 1
      form.delegation = this.$store.state.delegation

      return form
    },
    onFMSSearch(search, loading) {
      loading(true)
      this.searchFMS(loading, search, this)
    },
    searchFMS: debounce((loading, search, vm) => {
      getFMS(search).then(response => {
        vm.fms_options = response.data
        loading(false)
      })
    }, 350),
  }
}
</script>

